import React from "react"
import { Link } from "gatsby"
import Layout from "../components/fr/FRlayout"
import Image from "../components/image"
import SEO from "../components/seo"

const FRIndexPage = () => (
  <Layout>
    <SEO title="Inicio" />
    <h1>Inicio En Frances</h1>
  </Layout>
)

export default FRIndexPage
